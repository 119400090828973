import React from 'react';
import { Link } from 'gatsby';
// @ts-ignore
import { ReactComponent as LogoWTagline } from '../../assets/vectors/LogoWTagline.svg';

const LandingHeader = () => {
    return (
        <header className="py-2 md:py-4">
            <div className="flex items-center justify-center">
                <Link to="/" className="flex justify-center w-full">
                    <LogoWTagline width={250} height={41} />
                </Link>
            </div>
        </header>
    );
};

export default LandingHeader;
